import { render, staticRenderFns } from "./ProductSocials.vue?vue&type=template&id=0d9c459c&scoped=true&"
import script from "./ProductSocials.vue?vue&type=script&lang=js&"
export * from "./ProductSocials.vue?vue&type=script&lang=js&"
import style0 from "./ProductSocials.vue?vue&type=style&index=0&id=0d9c459c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d9c459c",
  null
  
)

export default component.exports